import React, { useState } from "react";
import AutomateDataService from "../services/AutomateService";

const AddAutomateData = () => {
  const initialMailState = {
    parsing_name: "",
    is_active: "",
    folder: "",
    keywords: "",
    end_mails: "",
  };
  const [automateData, setAutomateData] = useState(initialMailState);
  const [submitted, setSubmitted] = useState(false);
  const [checked, setChecked] = useState(true);
  const [keyword, setKeyword] = useState([
    { keyword_name: "", is_active: "", search_in: "" },
  ]);
  const [endMail, setEndMail] = useState([
    { email_address: "", is_active: "", mail_type: "" },
  ]);

  const newautomateData = () => {
    setAutomateData(initialMailState);
    setSubmitted(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAutomateData({ ...automateData, [name]: value });
  };

  const handleKeywordAdd = () => {
    setKeyword([
      ...keyword,
      { keyword_name: "", is_active: "", search_in: "" },
    ]);
  };

  const handleEndMailAdd = () => {
    setEndMail([
      ...endMail,
      { email_address: "", is_active: "", mail_type: "" },
    ]);
  };

  const handleKeywordDelete = (index) => {
    const list = [...keyword];
    list.splice(index, 1);
    setKeyword(list);
  };

  const handleEndMailDelete = (index) => {
    const emailList = [...endMail];
    emailList.splice(index, 1);
    setEndMail(emailList);
  };

  const handleKeywordChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...keyword];
    list[index][name] = value;
    setKeyword(list);
  };

  const handleEndMailChange = (e, index) => {
    const { name, value } = e.target;
    const emailList = [...endMail];
    emailList[index][name] = value;
    setEndMail(emailList);
  };

  const saveData = () => {
    var data = {
      parsing_name: automateData.parsing_name,
      is_active: checked,
      folder: automateData.folder,
      keywords: keyword,
      end_mails: endMail,
    };

    console.log("data", JSON.stringify(data));
    AutomateDataService.create(data)
      .then((response) => {
        setAutomateData({
          parsing_name: response.data.parsing_name,
          is_active: response.data.checked,
          folder: response.data.folder,
          keywords: response.data.keyword,
          end_mails: response.data.endMail,
        });
        setSubmitted(true);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="submit-form">
      {submitted ? (
        <div>
          <h4>You submitted successfully!</h4>
          <button className="btn btn-success" onClick={newautomateData}>
            Add
          </button>
        </div>
      ) : (
        <div>
          <div className="form-row">
            <div className="col-md-4 mb-2">
              <label htmlFor="parsing_name">Parsing Name</label>
              <input
                type="text"
                className="form-control"
                id="parsing_name"
                required
                value={automateData.parsing_name}
                onChange={handleInputChange}
                name="parsing_name"
              />
            </div>

            <div className="col-md-4 mb-2">
              <input
                type="checkbox"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
                style={{ marginRight: "10px" }}
              />
              <label htmlFor="pckbox">Is Active</label>
            </div>

            <div className="form-group col-md-4 mb-5">
              <label htmlFor="folder">Folder</label>
              <select
                onChange={handleInputChange}
                name="folder"
                className="form-control"
              >
                <option value="1">Inbox</option>
                <option value="2">Spam</option>
                <option value="3">Sent Mail</option>
                <option value="4">Draft</option>
                <option value="5">Trash</option>
              </select>
            </div>
          </div>
          {/* Add Keywords */}
          <table className="table table-bordered">
            <thead>
              <tr className="table-primary">
                <th scope="col">Keyword Name</th>
                <th scope="col">Is Active</th>
                <th scope="col">Search In</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>

            <tbody>
              {keyword.map((singleKeyword, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      id="kname"
                      required
                      onChange={(e) => handleKeywordChange(e, index)}
                      name="keyword_name"
                    />
                  </td>
                  <td>
                    <select
                      onChange={(e) => handleKeywordChange(e, index)}
                      name="is_active"
                      className="form-control"
                      required
                    >
                      <option value="Please" selected defaultValue disabled>
                        Please Select...
                      </option>
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </select>
                  </td>
                  <td>
                    <select
                      onChange={(e) => handleKeywordChange(e, index)}
                      name="search_in"
                      className="form-control"
                      required
                    >
                      <option value="Please" selected defaultValue disabled>
                        Please Select...
                      </option>
                      <option value="1">Keyword in Subject</option>
                      <option value="2">Keyword in Body</option>
                      <option value="3" defaultValue>
                        Keyword in Subject and Body
                      </option>
                      <option value="4">Keyword in from email address</option>
                      <option value="5">Keyword in bcc or cc</option>
                    </select>
                  </td>
                  <td>
                    {keyword.length - 1 === index ? (
                      <button
                        onClick={handleKeywordAdd}
                        className="btn btn-primary btn-sm"
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        onClick={() => handleKeywordDelete(index)}
                        className="btn btn-danger btn-sm"
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Add Tagging Mails */}
          <table className="table table-bordered">
            <thead>
              <tr className="table-primary">
                <th scope="col">Email Address</th>
                <th scope="col">Is Active</th>
                <th scope="col">Mail Type</th>
                <th scope="col">Delete</th>
              </tr>
            </thead>

            <tbody>
              {endMail.map((singleEndMail, index) => (
                <tr key={index}>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => handleEndMailChange(e, index)}
                      name="email_address"
                    />
                  </td>
                  <td>
                    <select
                      onChange={(e) => handleEndMailChange(e, index)}
                      name="is_active"
                      className="form-control"
                      required
                    >
                      <option value="Please" selected defaultValue disabled>
                        Please Select...
                      </option>
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </select>
                  </td>
                  <td>
                    <select
                      onChange={(e) => handleEndMailChange(e, index)}
                      name="mail_type"
                      className="form-control"
                      required
                    >
                      <option value="Please" selected defaultValue disabled>
                        Please Select...
                      </option>
                      <option value="1">Blind Carbon Copy</option>
                      <option value="2">Carbon Copy</option>
                      <option value="3">Forward Mail</option>
                    </select>
                  </td>
                  <td>
                    {endMail.length - 1 === index ? (
                      <button
                        onClick={handleEndMailAdd}
                        className="btn btn-primary btn-sm"
                      >
                        Add
                      </button>
                    ) : (
                      <button
                        onClick={() => handleEndMailDelete(index)}
                        className="btn btn-danger btn-sm"
                      >
                        Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button onClick={saveData} className="btn btn-success">
            Submit
          </button>
        </div>
      )}
    </div>
  );
};

export default AddAutomateData;
